<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "index",
  created(){
    this.$store.dispatch('getServiceSettings').then(()=>{
    })
  }
}
</script>

<style scoped>

</style>